import { useEffect, useState } from "react"
import { db } from "../firebase/firebase-core"
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

const DB_COLLECTION = "projects";

export const useFirestore = (currentCategory) => {

    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {
        setData(null)
        setIsPending(true)
        setError(null)

        let ref = query(collection(db, DB_COLLECTION), orderBy('completionDate', 'desc'));

        if (currentCategory !== "all projects") {
            ref = query(collection(db, DB_COLLECTION), where('projectCategory', 'array-contains-any', [currentCategory]), orderBy('completionDate', 'desc'))
        }

        const unsub = onSnapshot(ref, (snapshot) => {
            if (!snapshot.empty) {
                let results = []
                snapshot.docs.forEach((doc) => {
                    results.push({ id: doc.id, ...doc.data() })
                })
                //update state
                setIsPending(false)
                setData(results)

            } else {
                setIsPending(false)
                setData(null)
                setError("No Projects")
            }
        })

        return () => {
            unsub();
        };

    }, [currentCategory])

    return { data, error, isPending }
}