// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCnsFCiReYcGMqjzU5epNwBpCFaKlOxdAk",
  authDomain: "adarsh-motion-graphic.firebaseapp.com",
  projectId: "adarsh-motion-graphic",
  storageBucket: "adarsh-motion-graphic.appspot.com",
  messagingSenderId: "378646971087",
  appId: "1:378646971087:web:97ccb135bd0b49bacd6dba",
  measurementId: "G-WQ6V75XEZY"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const db = getFirestore(initializeApp)


export {db}