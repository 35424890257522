import { motion } from "framer-motion"
import Lottie from 'lottie-react';
import AdarshMotionGraphic_Light from './animations/AdarshMotionGraphic_Light2.json';
import AdarshMotionGraphic_Dark from './animations/AdarshMotionGraphic_Dark2.json';

export default function HeroSection() {
    return (
        <div className="hero mt-32">
            <div className="hero-content text-center">
                <div className="max-w-md">
                    {/* Background Animation */}
                    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.8}} className="relative -z-10 w-full max-w-md">
                        <div className="absolute top-0 -left-4  w-48 h-48 md:w-72 md:h-72 bg-secondary rounded-full filter blur-3xl first-letter:opacity-70 animate-blob animation-delay-2000"></div>
                        <div className="absolute -bottom-18 left-20  w-48 h-48 md:w-72 md:h-72 bg-[#F2543F] rounded-full opacity-70 filter blur-3xl animate-blob "></div>
                        <div className="absolute top-0 -right-4 w-48 h-48  md:w-72 md:h-72 bg-primary rounded-full opacity-70 filter blur-3xl animate-blob animation-delay-4000"></div>
                    </motion.div>

                    {/* Hero Info */}
                    <div className="avatar relative">
                        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.5}} className="w-28 sm:w-32 rounded-full shadow-xl bg-neutral">
                            <img alt="ProfilePic" src="./assets/Photo3_SolidBG_Sqr.png" />
                        </motion.div>
                    </div>
                    <div className="relative mt-6 h-10 sm:h-8 flex items-center justify-center">
                        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.5, delay:.1}} className="absolute"><Lottie animationData={AdarshMotionGraphic_Dark} className="invisible dark:visible h-30 sm:h-28" /></motion.div>
                        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.5, delay:.1}} className=""><Lottie animationData={AdarshMotionGraphic_Light} className="visible dark:invisible h-30 sm:h-28" /></motion.div>
                        
                    </div>
                    {/* <motion.h1 initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.5, delay:.1}} className="text-xl sm:text-2xl font-bold mt-2">Adarsh's Motion Graphic</motion.h1> */}
                    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.5, delay:.2}} className="mt-1">
                        <h1 className="font-thin text-sm sm:text-base tracking-tight ">I am a seasoned motion graphic designer, weaving stories through visuals that transcend boundaries and evoke emotions. With 4+ years of experience and a  Top Rated badge on Upwork, my creations ignite imagination and forge profound connections.</h1>
                    </motion.div>
                    <motion.div initial={{opacity:0}} animate={{ opacity:1}} transition={{duration:.8, delay:.3}}><a href="https://www.upwork.com/freelancers/~01f3c588d61f5b4b37" className="btn btn-normal font-medium btn-primary gap-2 mt-8 shadow-lg hover:shadow-md">
                        <svg fill="currentColor" className="h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path clipRule="evenodd" fillRule="evenodd" d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z"></path>
                            <path d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z"></path>
                        </svg>
                        Get In Touch</a></motion.div>
                </div>
            </div>
        </div>
    );
}