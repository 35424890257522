import React from "react"


export default function LoadingSkeleton(){

    return(
        [...Array(6)].map((_, index) => (
            <div key={index} className="rounded-md shadow-md group bg-neutral opacity-10 animate-pulse ">
                <div className="object-cover h-48 w-96"/>
            </div>
        ))
    )
}
