import HeroSection from "./components/hero-section";
import ProjectSection from "./components/project-section";
import Footer from "./components/footer";
import { Outlet } from "react-router-dom";






export default function Home() {

    


    return (
        <div>
            
            <HeroSection />
            <ProjectSection />
            <Footer />
            <Outlet />
        </div>
    );
}