import { useState } from "react";
import { Link } from "react-router-dom";
import { BlurhashCanvas } from "react-blurhash";
import { motion } from "framer-motion";

export default function Cards(props) {

    const [imgLoaded, setImgLoaded] = useState(false)

    const handleImageLoad = () => {
        setImgLoaded(true);

    };


    return (
        <Link to={`/project/${props.ProjectID}`}>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.8}} className="relative overflow-hidden rounded-md shadow-md hover:shadow-xl transition-all duration-300 group bg-neutral">
                <div className="absolute w-full h-full bg-black/30 opacity-0 transition duration-100 hover:opacity-100 flex justify-center flex-col items-center">
                </div>
                
                <img style={{ display: imgLoaded ? 'block' : 'none' }} loop="infinite" src={props.thumbnail} onLoad={handleImageLoad} alt={props.alt} className={`object-cover h-48 w-96`} />
                {!imgLoaded && <BlurhashCanvas
                    hash={props.blurhash}
                    width={384}
                    height={192}
                    punch={1}
                />
            }
                
                
            </motion.div>
        </Link>
    );
}