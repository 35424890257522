import Cards from "./cards";
import { useFirestore } from "../hooks/useFirestore";
import { useState, useRef} from "react";
import LoadingSkeleton from "./utilities/loading";
import Error from "./utilities/error";
import { motion } from "framer-motion"



let tabs = [
    { id: "featured", label: "Featured"},
    { id: "all projects", label: "All Projects" },
    { id: "logo animation", label: "Logo Animation" },
    { id: "animated explainer", label: "Animated Explainers" },
    { id: "app promos", label: "App Promos" },
];



export default function ProjectSection() {

    const [currentCategory, setCurrentCategory] = useState(tabs[0].id);

    const {data, error, isPending} = useFirestore(currentCategory)

    

    const tabsRef = useRef(null);

    const scrollTabsLeft = () => {
        tabsRef.current.scrollLeft -= 100; // Adjust scroll distance as needed
      };
    
      const scrollTabsRight = () => {
        tabsRef.current.scrollLeft += 100; // Adjust scroll distance as needed
      };

    return (
        
        <div className="mt-16">
            {/* Tab Section */}
            <motion.div initial={{opacity:0}} animate={{ opacity:1}} transition={{duration:.5, delay:.3}} className="flex justify-center ">
                <div className="flex overflow-x-scroll px-10 md:px-5 scroll-smooth no-scrollbar overflow-y-hidden items-center" ref={tabsRef}>
                    {/* Arrow Button Left */}
                    <button className="absolute z-[2] left-0 bg-primary rounded-r-full shadow-2xl visible sm:invisible p-1" onClick={scrollTabsLeft}><svg fill="none" className="w-6 h-6" stroke="white" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg></button>
                    <div className="h-8 absolute z-[1] ml-4 bg-gradient-to-r from-accent from-50% left-0 w-12 visible sm:invisible"> </div>

                    {/* Tabs */}
                    {tabs.map((tab, index) => (
                        <button
                            key={tab.id}
                            onClick={() => setCurrentCategory(tab.id)}
                            className={`${
                                currentCategory === tab.id ? "border-b-secondary text-neutral" : ""
                            } px-2 md:px-3 py-1.5 border-b-2 border-transparent text-sm inline-block  hover:opacity-80 transition-all duration-100 font-medium min-w-fit text-neutral`}
                        
                        >
                        {tab.label}
                        </button>
                        ))
                    }
                    {/* Arrow Button Left */}
                    <div className="h-8 absolute z-[1] right-0 mr-4 bg-gradient-to-l from-accent from-50% w-12 visible sm:invisible"> </div>
                    <button className="absolute z-[1] right-0 bg-primary rounded-l-full shadow-xl visible sm:invisible p-1" onClick={scrollTabsRight}><svg fill="none"  className="w-6 h-6" stroke="white" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                    </svg></button>
                </div>
            </motion.div>
            {error && <div className="flex m-4 justify-center">
                {error && <Error error={error}></Error>}
            </div>}
            {/* Cards Rendering */}
            <motion.div initial={{opacity:0}} animate={{ opacity:1}} transition={{duration:.8, delay:.3}} className="flex mt-2 sm:mt-0 justify-center">
                 
                <div className="max-w-5xl p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-4">
                    {
                        isPending ? ( <LoadingSkeleton/>
                        ) : (
                            data &&  data.map((data, index) => {
                                return <Cards key={index} ProjectID={data.id} blurhash={data.blurhash} thumbnail={data.thumbnailURL} alt={data.projectName} />
                            })
                        )
                    }
                </div>
                
                
            </motion.div>
        </div>
    );
}