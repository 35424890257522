
import { useEffect, useState } from "react"
import { db } from "../firebase/firebase-core";
import { useNavigate, useParams } from "react-router-dom"
import { doc, getDoc } from "firebase/firestore";
import {TopHalf, ClientFeedback, Stars, Feedback} from "./utilities/modalLoading";

const DB_COLLECTION = "projects";


export default function Modal(){

    const navigate = useNavigate()
    const params = useParams()
    const id = params.id

    const[isPending, setIsPending] = useState(true)
    const[error, setError] = useState()
    const [data, setData] = useState([])
    

    useEffect(()=>{
        const ref = doc(db, DB_COLLECTION, id);

        getDoc(ref).then((snapshot) => {
            if(snapshot.exists){
                setIsPending(false)
                setData(snapshot.data())
            }
            else{
                setError("Project Not Found")
                setIsPending(false)
            }
        })

    }, [id])

    const stars = (num)=>{
        let star = []
        
        for(let i = 0; i<num; i++){
            star.push(<svg key={i} fill="#CE890F" viewBox="0 0 24 24" className="h-5 w-5 sm:h-10 sm:w-10" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path clipRule="evenodd" fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" />
            </svg>)
        }
        return star;
    }

    function closeModal(){
        navigate("/")
    }
    
    return (
        <div className="fixed overflow-y-scroll inset-0 z-[3] bg-black/40 backdrop-blur-sm flex justify-center items-start">

            

            <div className="w-screen sm:mt-5 sm:w-11/12 md:w-8/12 sm:rounded-md p-2 bg-accent md:p-5 ">
                <button onClick={closeModal} className="btn btn-circle btn-sm">
                    <svg fill="none" stroke="currentColor" className="h-4 w-4" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
                    </svg>
                </button>
               
                <div className="w-full mt-2 bg-black rounded-md overflow-hidden flex justify-center">
                    <video className="w-full" src={data.videoURL} autoPlay={true} disablePictureInPicture={true} controls controlsList=" nodownload noremoteplayback noplaybackrate foobar"></video>
                </div>

                
            
                
                <div className="p-5">
                    
                    {isPending ? <TopHalf/> : <div className="">
                        <p className="text-lg sm:text-2xl font-normal">{data.projectName}</p>
                        <p className="text-sm mt-5 sm:text-base font-base">Client - {data.clientName}</p>
                        <p className="text-xs font-thinitalic opacity-60">{data.completionDate}</p>
                        
                    </div>}

                    <p className="text-xs sm:text-sm font-light mt-2 opacity-80 whitespace-pre-line">{data.description}</p>
                   

                    <div className="divider"></div>
                        <div className="pt-14 pb-14">
                            {isPending ? <ClientFeedback/> : <h1 className="text-center text-sm sm:text-lg font-bold">Client's Feedback</h1>}
                            
                            <div className="flex justify-center text-center">
                                {isPending? <div className="max-w-lg mt-2 sm:mt-4 "><Feedback/></div> : <p className="max-w-md mt-2 sm:mt-4 text-xs opacity-70 sm:text-sm italic font-light">"{data.feedback}"</p>}
                            </div>

                            <div className="flex justify-center mt-2">
                                {isPending ? <Stars/> : stars(data.stars)}
                            </div>
                        </div>
                        
                   </div>

                {error && <p>{error}</p>}
            </div>
        </div>
    )
}