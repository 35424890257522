export default function Footer(){
    return (
        <div className="mt-20 w-full flex justify-center bg-gray-800">
            <div className="max-w-5xl p-5 w-full h-full text-center md:text-right">
                <h1 className="text-slate-200 text-sm sm:text-base font-light" >Self Designed and Developed</h1> 
                <h1 className="text-slate-200 text-xs sm:text-sm font-extralight" >&copy; {new Date().getFullYear()} Adarsh's Motion Graphic</h1> 
                
            </div>
            
        </div>
    )
}