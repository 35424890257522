


export function TopHalf() {
    return (
        <div className="animate-pulse">
            <div className="w-full h-16 bg-neutral rounded-md opacity-10"></div>
            <div className="mt-5 w-40 h-5 rounded-md bg-neutral opacity-10"></div>
            {/* <div className="mt-2 w-24 h-5 rounded-md bg-neutral opacity-10"></div> */}
            <div className="mt-4 w-full h-24 bg-neutral rounded-md opacity-10"></div>
        </div>
    )
}

export function ClientFeedback() {
    return (
        <div className="animate-pulse">
            <div className="flex justify-center"><div className="w-40 h-8 rounded-md bg-neutral opacity-10"></div></div>
        </div>
    )
}

export function Stars() {
    return (
        <div className="animate-pulse">
            <div className="w-48 h-10 rounded-md bg-neutral opacity-10"></div>
        </div>
    )
}

export function Feedback() {
    return (
        <div className="animate-pulse">
            <div className="w-96 h-20 rounded-md bg-neutral opacity-10"></div>
        </div>
    )
}