import { Routes, Route } from "react-router-dom"

import Modal from "./components/modal";
import Home from "./Home";



function App() {


  return (
    <div className="App overflow-x-hidden">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/project/:id" element={<Modal />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
